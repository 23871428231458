import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51TwW6cMBC98xW+VMoevAJCqMoeK/UXcowMHsCJ8Vi2YdlU+ffKBnZZdhs1vcF7Hr95b8b7l6R/G/NufCS/I0I0WuEEqoLUYgR+iAgRyoIrSOy/S1a9NQZ7xWmFEk1BBmYeKGV1LRTQFd0hZ3I6tPOV71QoDuOmYEan07tD9BHtl36yf+mHC6slOxWkljB6gEnRKCocdLYgFSgHxsOvvXWiPtEKlQPl1tSXG3sKjVG6/McFOVwByRZIJ+AouGsXnXN1SKcF0bRuyyWB64Si9/k08CWO1Ip3oZqClGg4GFpiCKNG5TwFG3MBL5mF3fnUcRbI4pCr9McW0WSfe0wzzoNIGuuRpJkOGpcBGZDMiQG+tic4gJHsRDVTsN6X4KplHI+bWo3a18zkfDa4NoyL3hYkSafWOjaePVRMVg9JHA8toeQx1WMoxN55pwVRqOBqyPlm+1hpUfYumHOoC5LOIma632fyX9v0PQh9OqmW5reDyu8OKnuajJtGKFqic9gteaw0fwTNm7dz80g8QUHxTx7WvBQ0ZJLFS/BBP2Csd+ixhuklpXUn+xDJL0QH5lm49mcrJDegpvjXl9+pNTCAsTAl6HvlwkA1byMe6cz/3Zt1zLirS1m4bG3gsk1XoS4DP2cfx9/CSg1gaolHerp4P7+cmGQbE+X9UWzdVCj7Th2ijz8ROfc9rwUAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mx6';
export var confirmModalContainer = '_1ukx6mxb';
export var confirmModalContent = '_1ukx6mxa';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mx5';
export var modalContentWrapper = '_1ukx6mx4';
export var modalDescription = '_1ukx6mx8';
export var modalFooter = '_1ukx6mx9';
export var modalHeader = '_1ukx6mx7';
export var modalOverlay = '_1ukx6mx3';
export var widthVar = 'var(--_1ukx6mx0)';