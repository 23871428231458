import "../component/src/ui/notification/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/notification/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4VU0W7bMAx871dwAQYsDyrsNOlaFf2SoRhki7bZKpIhMXGyof8+SHYW203aR5E0j3c8+vZ3/noo3nS9gb83AIXzGr3wStMuSHhoD0/naEeaGwn5JBj4aFBCcIZ0Hz6I0CjtOgl75X8IoaqKLA5BkS9TlSrfau92VovSGedPtadpsuUI4mLFKlVcTEWI95vb0/M+MWuV1mRrCfl9nH+U/5nymkJr1FFCZTDxU4ZqK4hxG/qgCKw8x9TrLjBVR1E6y2hZQmhViaJA7hDtpPlDat4g1Q1LWK177T4HK9Ey+kmbx9RmWMDVLq2JY8y6AGyVr8kK38+QZzP+jxD29bh/nmXfn0ZTn94XxV5PxVbjRln8KklX++iHPL7dHn1l4rMhraNcAIwHFucEGkNtoBBTXUOMIgkswbrOqzZ1dZZFNwy4zhKSiTabS50KA/1BCfmmD32hR5EozIrmpilT0UVF7pIzWxeIyVkJHo1i2uPU9xLYKxta5dHy2eyRo8UplJQFVs7jADl4brGYwqgiOLPjBEM2IA/6z06abIOe+LMjHA62MKp8S1xcq0rio4TsNlvPZmvi2oZ/x/nyIwn4RtvWeVaR3+gb/UsrVqIyTvHzgv0OFy/9iV7kwq496Q9wbR14fR358tog1XjRCWbzwTMTRfr4dvnRbKt+np5ZR9wIKp19gTNWn0p3/rxgYvOfdf9jEgYrlnCXbPv+D92KpB+XBQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = '_1jxbkdgb';
export var actionButton = '_1jxbkdgc';
export var actionTextColor = 'var(--_1jxbkdg3)';
export var card = '_1jxbkdg5';
export var cardBorderColor = 'var(--_1jxbkdg2)';
export var cardColor = 'var(--_1jxbkdg0)';
export var cardForeground = 'var(--_1jxbkdg1)';
export var cardInner = '_1jxbkdg6';
export var closeButton = '_1jxbkdgd';
export var closeIcon = '_1jxbkdge';
export var headAlignWrapper = '_1jxbkdg8';
export var header = '_1jxbkdg7';
export var icon = '_1jxbkdg9';
export var iconColor = 'var(--_1jxbkdg4)';
export var main = '_1jxbkdgf';
export var title = '_1jxbkdga';